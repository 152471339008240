import { MemoType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { MemoData } from 'packages/innedit';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/memo.json';
import { ReducerCMSProps } from '~/reducers/cms';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageMemosCreate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId },
}) => {
  const model = new MemoData({ espaceId, params });

  const channels = useSelector(
    (state: { cms: ReducerCMSProps }) => state.cms.channels,
  );

  if (!channels) {
    return (
      <TemplateEspace espace={espace} user={user}>
        <CMSView>Chargement en cours des paramètres</CMSView>
      </TemplateEspace>
    );
  }

  const initializeData: Partial<MemoType> = {
    channels:
      channels && channels.length > 0
        ? channels.reduce((acc, value) => {
            const newAcc = { ...acc };
            newAcc[value.id] = {
              isVisible: true,
            };

            return newAcc;
          }, {} as { [key: string]: { isVisible: boolean } })
        : {},
  };

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={model.getNewDocId()}
          initializeData={initializeData}
          itemPathnamePrefix={`/espaces/${espaceId}/memos/`}
          model={model}
          name="memo"
          type="create"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageMemosCreate);
